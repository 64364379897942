import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core/';
import OrganisationLayoutContainer from '../../Components/Layout/LayoutContainer';
import AuthMenu from '../../Components/Organisations/Shared/Menu';
import LeadsActions from '../../Redux/LeadsRedux';

type Props = {
  family: Object,
  organisation: Object,
};

type RowProps = {
  lead: Object,
};

const useStyles = makeStyles(theme => ({
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  progress: {
    transform: 'translateY(-8px)',
    marginBottom: '-4px',
  },
  transport: {
    padding: '16px',
  },
}));

function Row({ lead }: RowProps) {
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const getLeadTotalDistance = lead => {
    return (
      parseFloat(lead.meta?.distance || 0) +
      parseFloat(lead.meta?.volunteer_departure_distance || 0)
    ).toFixed(2);
  };

  const getLeadTotalAmount = lead => {
    return (
      parseFloat(lead.meta?.partner_helped_refund_amount || 0) +
      parseFloat(lead.meta?.partner_volunteer_refund_amount || 0)
    ).toFixed(2);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell>{moment(lead.meta.departure_date).format('LLL')}</TableCell>
        <TableCell>
          {lead.helped ? lead.helped.fullname : lead.helper?.fullname}
          <br />
          De {lead.meta.departureFormattedAddress} à {lead.meta.arrivalFormattedAddress}
        </TableCell>
        <TableCell>{lead.archived ? t('YES') : t('NO')}</TableCell>
        <TableCell>{getLeadTotalDistance(lead)} km</TableCell>
        <TableCell>{getLeadTotalAmount(lead)} €</TableCell>
        <TableCell></TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={5}>
              <Typography variant="h6" gutterBottom component="div">
                Détail :
              </Typography>
              <List>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.ROUNDTRIP')} :{' '}
                  {lead.meta.round_trip ? t('YES') : t('NO')}
                </ListItem>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.HELPED_DISTANCE')} :{' '}
                  {parseFloat(lead.meta?.distance || 0)} km
                </ListItem>
                <ListItem>
                  {t('APP.FAMILY.TRANSPORT.HEADER.VOLUNTEER_DISTANCE')} :{' '}
                  {lead.meta?.volunteer_departure_distance
                    ? parseFloat(lead.meta?.volunteer_departure_distance).toFixed(2) + ' km'
                    : t('APP.FAMILY.TRANSPORT.VOLUNTEER_DISTANCE_NOT_CALCULATED')}
                </ListItem>
                <ListItem>
                  {t('APP.LEAGUES.SETTINGS.TRANSPORT_PARTNER_REFUND_VOLUNTEER')} :{' '}
                  {lead.meta?.partner_volunteer_refund_amount
                    ? parseFloat(lead.meta?.partner_volunteer_refund_amount || 0).toFixed(2) + '€'
                    : t('APP.FAMILY.TRANSPORT.VOLUNTEER_DISTANCE_NOT_CALCULATED')}
                </ListItem>
                <ListItem>
                  {t('APP.LEAGUES.SETTINGS.TRANSPORT_PARTNER_REFUND_HELPED')} :{' '}
                  {lead.meta?.partner_helped_refund_amount
                    ? parseFloat(lead.meta?.partner_helped_refund_amount || 0).toFixed(2) + '€'
                    : t('APP.FAMILY.TRANSPORT.VOLUNTEER_DISTANCE_NOT_CALCULATED')}
                </ListItem>
              </List>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const Transport = ({ family, organisation }: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const leads = useSelector(state => state.leads.leads);
  const totalLeads = useSelector(state => state.leads.totalleads);
  const isFetching = useSelector(state => state.leads.isFetching.getFamilyLeads);
  const [pageFilters, setPageFilters] = React.useState({
    currentPage: 0,
    filterTransport: true,
  });

  const leadsFetch =
    !isFetching &&
    leads &&
    leads.length !== 0 &&
    (leads[0].helper?.id === family?.admin?.id ||
      leads[0].helped?.id === family?.admin?.id ||
      leads[0].responsible_id === family?.admin?.id);

  React.useEffect(() => {
    dispatch(
      LeadsActions.getFamilyLeadsRequest(
        family?.admin?.id,
        pageFilters.filterArchived,
        pageFilters.currentPage,
        pageFilters.filterTransport,
      ),
    );
  }, [dispatch, pageFilters]);

  return (
    <>
      <OrganisationLayoutContainer menu={<AuthMenu organisation={organisation} family={family} />}>
        <div className={classes.appBarContainer}>
          <AppBar position="static" color="inherit" elevation={1}>
            <Toolbar style={{ flexWrap: 'wrap' }}>
              <Typography className={classes.grow} variant="h5" component="h2">
                {t('APP.FAMILY.TRANSPORT.APP_BAR.TITLE')}
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
        <Grid item md={12}>
          {isFetching && <LinearProgress className={classes.progress} />}
          {leadsFetch && (
            <Paper>
              <div className={classes.transport}>
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.DATE')}</TableCell>
                        <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.DESCRIPTION')}</TableCell>
                        <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.ARCHIVED')}</TableCell>
                        <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.TOTAL_DISTANCE')}</TableCell>
                        <TableCell>{t('APP.FAMILY.TRANSPORT.HEADER.AMOUNT')}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {leads.map((lead, index) => (
                        <Row key={index} lead={lead} />
                      ))}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={false}
                          colSpan={6}
                          count={totalLeads}
                          rowsPerPage={30}
                          page={pageFilters.currentPage}
                          onPageChange={(event, page) =>
                            setPageFilters({ ...pageFilters, currentPage: page })
                          }
                          labelDisplayedRows={({ from, to, count }) =>
                            `${from}-${to} sur ${count !== -1 ? count : `plus de ${to}`}`
                          }
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </Paper>
          )}
        </Grid>
      </OrganisationLayoutContainer>
    </>
  );
};

export default Transport;
