import './App.scss';
import React from 'react';
import loadable from '@loadable/component';
import { Route, Redirect, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import { withStyles } from '@material-ui/core/styles';
import fp from 'lodash/fp';
import { Helmet } from 'react-helmet';
import CookieBanner from 'react-cookie-banner';

import { Metrics, Images, muiTheme } from './Themes';
import Toaster from './Components/Layout/Toaster';
import RequestProgress from './Components/Layout/RequestProgress';
import NavBar from './Components/Layout/NavBar';

import OrganisationAuthorization from './Acl/OrganisationAuthorization';
import HoldingAuthorization from './Acl/HoldingAuthorization';
import LeagueAuthorization from './Acl/LeagueAuthorization';
import FamilyAuthorization from './Acl/FamilyAuthorization';
import Authentication from './Acl/Authentication';
import CheckTabValue from './Components/Shared/Algolia/CheckTabValue';
import ScrollManager from './Routing/ScrollManager';
import DynamicRouter from './Routing/DynamicRouter';
import HostConstraint, { checkHostConstraint, HOSTS_NAMES } from './Routing/HostConstraint';
import Routes from './Routing/Routes';
import withOrganisation from './Routing/WithOrganisation';
import withFamily from './Routing/WithFamily';
import withLeague from './Routing/WithLeague';
import withTask from './Routing/WithTask';
import withCopiedTask from './Routing/WithCopiedTask';
// import withCourse from './Routing/WithCourse';
import withHoldingArticle from './Routing/WithHoldingArticle';
import withLeagueArticle from './Routing/WithLeagueArticle';
import withClub from './Routing/WithClub';
import withHolding from './Routing/WithHolding';
import withRoot from './withRoot';
import withStructure from './Routing/WithStructure';

import Seo from './Routing/Seo';
// import { isClientSide } from './Config/ServerConfig';
import WebSocketUserListener from './Components/Layout/WebSocketUserListener';
import { getCdnUrl } from './Services/ImageResizer';
import {
  HelpersIcon,
  HomecareIcon,
  HelperhelpIcon,
  ResidenceIcon,
  RetirementIcon,
  EstablishmentIcon,
  CareIcon,
} from './Themes/Icon';
import ButtonCallMeBackInModal from './Components/Shared/ButtonCallMeBackInModal';
// -------- Signup Pages --------
const SignIn = loadable(() => import('./Pages/Authentication/SignIn'));
const OrganisationSignUp = loadable(() => import('./Pages/Authentication/Organisation/SignUp'));
const OrganisationSignUpPending = loadable(() =>
  import('./Pages/Authentication/Organisation/SignUpPending'),
);
const OrganisationSignInRequired = loadable(() =>
  import('./Pages/Authentication/Organisation/SignInRequired'),
);
const OrganisationResendConfirmationEmail = loadable(() =>
  import('./Pages/Authentication/Organisation/ResendConfirmationEmail'),
);
const SignUpFinalization = loadable(() =>
  import('./Pages/Authentication/Organisation/SignUpFinalization'),
);
const SignUpConfirmation = loadable(() => import('./Pages/Authentication/SignUpConfirmation'));
const ResetPassword = loadable(() => import('./Pages/Authentication/ResetPassword'));
const NewPassword = loadable(() => import('./Pages/Authentication/NewPassword'));
const EmailToken = loadable(() => import('./Pages/Authentication/EmailToken'));

// -------- Static Pages --------
const Page = loadable(() => import('./Pages/Pages/Page'));
const Cgu = loadable(() => import('./Pages/Other/CGU'));
const Privacy = loadable(() => import('./Pages/Other/Privacy'));
const Mention = loadable(() => import('./Pages/Other/Mention'));
const CareManager = loadable(() => import('./Pages/Other/CareManager'));
const Pricing = loadable(() => import('./Pages/Other/Pricing'));
const Partner = loadable(() => import('./Pages/Other/Partner'));
// const OfferHelper = loadable(() => import('./Pages/Other/OfferHelper'));
// const OfferPro = loadable(() => import('./Pages/Other/OfferPro'));
const OfferCare = loadable(() => import('./Pages/Other/OfferCare'));
const OfferPartner = loadable(() => import('./Pages/Other/OfferPartner'));
// const OfferEstablishment = loadable(() => import('./Pages/Other/OfferEstablishment'));
const OfferRetirement = loadable(() => import('./Pages/Other/OfferRetirement'));
const OfferResidence = loadable(() => import('./Pages/Other/OfferResidence'));
const OfferHelperHelp = loadable(() => import('./Pages/Other/OfferHelperHelp'));
const OfferHelpers = loadable(() => import('./Pages/Other/OfferHelpers'));
const OfferHomeCare = loadable(() => import('./Pages/Other/OfferHomeCare'));

const LeadQuickActions = loadable(() => import('./Pages/QuickActions/Lead'));
const Notifications = loadable(() => import('./Pages/Notifications'));

// -------- Families Pages --------
const FamilyShowLead = loadable(() => import('./Pages/Families/ShowLead'));
const FamilyLeads = loadable(() => import('./Pages/Families/Leads'));
const FamilyCourses = loadable(() => import('./Pages/Families/Courses'));
const FamilyHome = loadable(() => import('./Pages/Families/Home'));
const FamilySchedule = loadable(() => import('./Pages/Families/Schedule'));
const FamilyTransport = loadable(() => import('./Pages/Families/Transport'));
const CareNotebookIndex = loadable(() => import('./Pages/CareNotebook/CareNotebook'));
const QuickAccessForm = loadable(() => import('./Pages/QuickAccess/QuickAccessForm'));

// -------- Quotation Pages --------
const Quotation = loadable(() => import('./Pages/Organisations/Quotation/Quotation'));
const QuotationSuccess = loadable(() => import('./Pages/Organisations/Quotation/QuotationSuccess'));

// -------- Organisations Pages --------
const OrganisationHome = loadable(() => import('./Pages/Organisations/Home'));
const OrganisationShowLead = loadable(() => import('./Pages/Organisations/Leads/ShowLead'));
const OrganisationShowTwig = loadable(() => import('./Pages/Organisations/Tasks/ShowTwig'));
const OrganisationTaskStepForm = loadable(() => import('./Pages/Organisations/Tasks/TaskStepForm'));
const OrganisationShowService = loadable(() =>
  import('./Pages/Organisations/Services/ShowService'),
);
// const OrganisationShowCourse = loadable(() => import('./Pages/Organisations/Courses/ShowCourse'));
// const OrganisationCourseForm = loadable(() => import('./Pages/Organisations/Courses/CourseForm'));
const OrganisationNew = loadable(() => import('./Pages/Holding/Settings/OrganisationNew'));
const OrganisationClubMembers = loadable(() => import('./Pages/Organisations/Clubs/ClubMembers'));
const HomeCommunity = loadable(() => import('./Pages/Communities/Home'));

// -------- Holding Pages --------
const HoldingHome = loadable(() => import('./Pages/Holding/Home'));
const HoldingLeadsIndex = loadable(() => import('./Pages/Holding/Leads'));
const HoldingArticleForm = loadable(() => import('./Pages/Holding/Articles/HoldingArticleForm'));
const HoldingNew = loadable(() => import('./Pages/Leagues/Settings/HoldingNew'));
const HoldingCourseManage = loadable(() => import('./Pages/Holding/Courses/HoldingCourseManage'));
const HoldingChapterManage = loadable(() => import('./Pages/Holding/Courses/HoldingChapterManage'));

// -------- Leagues Pages --------
const LeagueSettingsIndex = loadable(() => import('./Pages/Leagues/Settings'));
const LeagueAnalyticsIndex = loadable(() => import('./Pages/Leagues/Analytics'));
const LeagueHoldingsIndex = loadable(() => import('./Pages/Leagues/Settings/LeagueHoldings'));
const LeagueHelpersIndex = loadable(() => import('./Pages/Leagues/Settings/LeagueHelpers'));
const LeagueArticlesIndex = loadable(() => import('./Pages/Leagues/Articles/Articles'));
const LeagueArticleForm = loadable(() => import('./Pages/Leagues/Articles/LeagueArticleForm'));
const LeagueCoursesIndex = loadable(() => import('./Pages/Leagues/Courses/Courses'));
const LeagueCourseManage = loadable(() => import('./Pages/Leagues/Courses/LeagueCourseManage'));
const LeagueChapterManage = loadable(() => import('./Pages/Leagues/Courses/LeagueChapterManage'));
const LeagueCommunitiesIndex = loadable(() => import('./Pages/Leagues/Communities/Communities'));
const LeagueCommunityForm = loadable(() =>
  import('./Pages/Leagues/Communities/LeagueCommunityForm'),
);
const LeagueShowLead = loadable(() => import('./Pages/Leagues/Leads/ShowLead'));
const LeagueShowHelper = loadable(() => import('./Pages/Leagues/Settings/ShowHelper'));

// -------- Settings Pages --------
const Statistics = loadable(() => import('./Pages/UserStatistics/Statistics'));
const OrganisationStatistics = loadable(() => import('./Pages/UserStatistics/OrgaStatistics'));
const HoldingStatistics = loadable(() => import('./Pages/UserStatistics/HoldingStatistics'));
const UserSecurity = loadable(() => import('./Pages/UserSetup/UserSecurity'));
const UserSetup = loadable(() => import('./Pages/UserSetup/UserSetup'));
const SetupSuccess = loadable(() => import('./Pages/UserSetup/SetupSuccess'));

// -------- Other Pages --------
const ShowArticle = loadable(() => import('./Pages/Other/ShowArticle'));
const ShowCourse = loadable(() => import('./Pages/Other/ShowCourse'));
const CourseUnitSuccess = loadable(() => import('./Pages/Other/ShowCourseUnitSuccess'));
const ShowChapter = loadable(() => import('./Pages/Other/ShowChapter'));

// -------- Leagues Customs Pages --------
const WelloHome = loadable(() => import('./PagesLeagues/Wello/Home'));
const WelloSearchResultsMain = loadable(() => import('./PagesLeagues/Wello/SearchResultsMain'));
const LeaguesCandidate = loadable(() => import('./PagesLeagues/Candidate'));

const UnaHome = loadable(() => import('./PagesLeagues/Una/Home'));
const UnaPro = loadable(() => import('./PagesLeagues/Una/Pro'));
const UnaSearchResults = loadable(() => import('./PagesLeagues/Una/SearchResults'));

const ScalaaHome = loadable(() => import('./PagesLeagues/Scalaa/Home'));
const ScalaaPro = loadable(() => import('./PagesLeagues/Scalaa/Pro'));

const MormalHome = loadable(() => import('./PagesLeagues/Mormal/Home'));
const MormalSearchResults = loadable(() => import('./PagesLeagues/Mormal/SearchResults'));
const MormalSearchResultsAlgolia = loadable(() =>
  import('./PagesLeagues/Mormal/SearchResultsAlgolia'),
);

const SLAHome = loadable(() => import('./PagesLeagues/SLA/Home'));
const SLASearchResults = loadable(() => import('./PagesLeagues/SLA/SearchResults'));
const SLAVideo = loadable(() => import('./PagesLeagues/SLA/Video'));

const CCMormalHome = loadable(() => import('./PagesLeagues/CCMormal/Home'));
const CCMormalSearchResults = loadable(() => import('./PagesLeagues/CCMormal/SearchResults'));

const DiapasonHome = loadable(() => import('./PagesLeagues/Diapason/Home'));

const RepriseHome = loadable(() => import('./PagesLeagues/Reprise/Home'));
const ReprisePro = loadable(() => import('./PagesLeagues/Reprise/Pro'));
const RepriseMethode = loadable(() => import('./PagesLeagues/Reprise/Methode'));
const RepriseSearchResults = loadable(() => import('./PagesLeagues/Reprise/SearchResults'));

const CD85Home = loadable(() => import('./PagesLeagues/CD85/Home'));
const CD85SearchResults = loadable(() => import('./PagesLeagues/CD85/SearchResults'));

const HapicoopHome = loadable(() => import('./PagesLeagues/Hapicoop/Home'));

// const CHBHome = loadable(() => import('./PagesLeagues/CHB/Home'));
const CHBSearchResults = loadable(() => import('./PagesLeagues/CHB/SearchResults'));
const CHBCgu = loadable(() => import('./PagesLeagues/CHB/CGU'));
const CHBHelpedTool = loadable(() => import('./PagesLeagues/CHB/HelpedTool'));
const CHBPrivacy = loadable(() => import('./PagesLeagues/CHB/Privacy'));

const GlobalHome = loadable(() => import('./PagesLeagues/Global/Home'));
const GlobalSearchResults = loadable(() => import('./PagesLeagues/Global/SearchResults'));

const NEHome = loadable(() => import('./PagesLeagues/NE/Home'));
const NESearchResults = loadable(() => import('./PagesLeagues/NE/SearchResults'));

const SOSSHome = loadable(() => import('./PagesLeagues/SOSS/Home'));
const SOSSSearchResults = loadable(() => import('./PagesLeagues/SOSS/SearchResults'));

const NoMatch404 = loadable(() => import('./Pages/NoMatch404'));
const NoMatch401 = loadable(() => import('./Pages/NoMatch401'));
const ErrorBoundary = loadable(() => import('./Pages/ErrorBoundary'));

// import NoMatch404 from './Pages/NoMatch404';

type Props = {
  classes: Object,
  context: Object,
};

const AclCurrentUserBelongsToOrganisation = action => OrganisationAuthorization(action);
const AclCurrentUserBelongsToFamily = action => FamilyAuthorization(action);
const AclCurrentUserBelongsToHolding = action => HoldingAuthorization(action);
const AclCurrentUserBelongsToLeague = action => LeagueAuthorization(action);

const withTaskAndOrganisationAndAcl = fp.flow([
  withTask,
  AclCurrentUserBelongsToOrganisation(''),
  withOrganisation,
]);

const withCopiedTaskAndOrganisationAndAcl = fp.flow([
  withCopiedTask,
  AclCurrentUserBelongsToOrganisation(''),
  withOrganisation,
]);

const withTaskAndFamilyAndAcl = fp.flow([withTask, AclCurrentUserBelongsToFamily(''), withFamily]);
const withCopiedTaskAndFamilyAndAcl = fp.flow([
  withCopiedTask,
  AclCurrentUserBelongsToFamily(''),
  withFamily,
]);

// const withCourseAndOrganisation = fp.flow([withCourse, withOrganisation]);

// const withCourseAndOrganisationAndAcl = fp.flow([
//   withCourse,
//   AclCurrentUserBelongsToOrganisation(''),
//   withOrganisation,
// ]);

const withClubAndOrganisationAndAcl = fp.flow([
  withClub,
  AclCurrentUserBelongsToOrganisation(''),
  withOrganisation,
]);

const withArticleAndHolding = fp.flow([
  withHoldingArticle,
  AclCurrentUserBelongsToHolding(''),
  withHolding,
]);
const withArticleAndLeague = fp.flow([withLeagueArticle, withLeague]);

const enhancedEditTaskForm = withTaskAndOrganisationAndAcl(OrganisationTaskStepForm);
const enhancedCopyTaskForm = withCopiedTaskAndOrganisationAndAcl(OrganisationTaskStepForm);
const enhancedEditFamilyTaskForm = withTaskAndFamilyAndAcl(OrganisationTaskStepForm);
const enhancedCopyFamilyTaskForm = withCopiedTaskAndFamilyAndAcl(OrganisationTaskStepForm);
// const enhancedShowCourse = isClientSide()
//   ? withCourseAndOrganisation(OrganisationShowCourse)
//   : withCourse(OrganisationShowCourse);
// const enhancedEditCourseForm = withCourseAndOrganisationAndAcl(OrganisationCourseForm);
const enhancedClubMembers = withClubAndOrganisationAndAcl(OrganisationClubMembers);

const styles = theme => ({
  main: {
    marginTop: Metrics.navBarHeight,
    [`${theme.breakpoints.up('xs')} and (orientation: landscape)`]: {
      marginTop: Metrics.navBarHeight - theme.spacing(1),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: Metrics.navBarHeight + theme.spacing(1),
    },
  },
});

function App({ classes, context }: Props) {
  const welloHost = checkHostConstraint(HOSTS_NAMES.wello);
  const unaHost = checkHostConstraint(HOSTS_NAMES.una);
  const lascalaaHost = checkHostConstraint(HOSTS_NAMES.lascalaa);
  const mormalHost = checkHostConstraint(HOSTS_NAMES.mormal);
  const slaHost = checkHostConstraint(HOSTS_NAMES.sla);
  const ccmormalHost = checkHostConstraint(HOSTS_NAMES.ccmormal);
  const diapasonHost = checkHostConstraint(HOSTS_NAMES.diapason);
  const repriseHost = checkHostConstraint(HOSTS_NAMES.reprise);
  const cd85Host = checkHostConstraint(HOSTS_NAMES.cd85);
  const hapicoopHost = checkHostConstraint(HOSTS_NAMES.hapicoop);
  const chbHost = checkHostConstraint(HOSTS_NAMES.chb);
  const globalHost = checkHostConstraint(HOSTS_NAMES.global);
  const neHost = checkHostConstraint(HOSTS_NAMES.ne);
  const sossHost = checkHostConstraint(HOSTS_NAMES.soss);
  const { t } = useTranslation();

  return (
    <React.StrictMode>
      <ErrorBoundary>
        <WebSocketUserListener />
        <DynamicRouter context={context}>
          <RequestProgress />
          <Helmet>
            <script type="application/ld+json">{`
          {
              "@context": "http://schema.org",
              "@type": "WebSite",
              "name": "${t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE`)}",
              "url": "${t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.URL`)}",
              "sameAs" : [
  "https://www.facebook.com/besoinaideadomicile.fr/",
  "https://www.instagram.com/besoinaideadomicile/"
 ]
          }
        `}</script>
            {Seo.title(t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.TITLE`))}
            {Seo.description(t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.DESCRIPTION`))}
            {Seo.url(t(`${Routes.currentNamespace()}.SEO.HOMEPAGE.URL`))}
            {Seo.image(Images[Routes.currentNamespace().toLowerCase()].seo)}
            <meta property="og:type" content="website" />
            <meta property="og:locale" content="fr_FR" />
            <meta
              property="og:site_name"
              content={t(`${Routes.currentNamespace()}.SEO.SITENAME`)}
            />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="robots" content="index,follow" />
            <meta name="language" content="fr" />
          </Helmet>
          <CookieBanner
            styles={{
              banner: {
                position: 'fixed',
                bottom: '0',
                width: '100%',
                minHeight: '57px',
                height: 'auto',
                background: 'rgba(64, 64, 64, 0.88) url(/cookie.png) 20px 50% no-repeat',
                backgroundSize: '30px 30px',
                fontSize: '15px',
                fontWeight: 600,
              },
              button: {
                border: '1px solid white',
                borderRadius: 4,
                width: 66,
                height: 32,
                lineHeight: '32px',
                background: 'transparent',
                color: 'white',
                fontSize: '14px',
                fontWeight: 600,
                opacity: 1,
                right: 20,
                marginTop: -18,
              },
              message: {
                display: 'block',
                padding: '10px 90px 10px 70px',
                lineHeight: 1.3,
                textAlign: 'left',
                color: 'white',
              },
            }}
            message="En poursuivant votre navigation sans modifier vos paramètres, vous acceptez l'utilisation des cookies susceptibles de réaliser des statistiques de visite"
            dismissOnScroll={false}
            dismissOnClick
            buttonMessage="Fermer"
          />
          <ScrollManager>
            <main className={classes.main}>
              <Switch>
                <Route
                  path="/families/:family_slug/users/setup/info"
                  exact
                  component={Authentication(UserSetup)}
                />
                <Route
                  path="/:holding_slug/users/setup/info"
                  exac
                  component={withHolding(Authentication(UserSetup))}
                />
                <Route
                  path="/:holding_slug/:organisation_slug/users/setup/info"
                  exact
                  component={withOrganisation(Authentication(UserSetup))}
                />
                <div>
                  <HostConstraint hosts={HOSTS_NAMES.wello}>
                    <Switch>
                      <Route path="/care-manager" component={CareManager} exact />
                      <NavBar
                        logo={Images.wello.home}
                        mailto="hello@wello.fr"
                        leftTree={[
                          {
                            title: 'Nos solutions',
                            items: [
                              {
                                title: 'AIDANTS',
                                subtitle: 'Aidant, famille, ami, voisin…',
                                offers: [
                                  {
                                    title: 'Aidants',
                                    subtitle:
                                      'Une application pour échanger et vous organiser au quotidien',
                                    link: '/solution-numerique-aidants',
                                    icon: (
                                      <HelpersIcon
                                        style={{
                                          fill: muiTheme.wello.helpers.main,
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: muiTheme.wello.helpers.main,
                                  },
                                ],
                              },
                              {
                                title: 'PROFESSIONNELS',
                                subtitle:
                                  'SAAD, SSIAD, EPHAD, SAMSAH, SAVS, Résidence seniors, Plateforme de répit... ',
                                offers: [
                                  {
                                    title: 'Médico-social',
                                    subtitle:
                                      'Dossier usager informatisé, Services à domicile libérés...',
                                    link: '/solution-numerique-medico-social',
                                    icon: (
                                      <HomecareIcon
                                        style={{
                                          fill: muiTheme.wello.homecare.main,
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: muiTheme.wello.homecare.main,
                                  },
                                  {
                                    title: 'Aide aux aidants',
                                    subtitle:
                                      'Aider les aidants avec plus de services en ligne et les accompagner au quotidien',
                                    link: '/solution-numerique-aide-aux-aidants',
                                    icon: (
                                      <HelperhelpIcon
                                        style={{
                                          fill: muiTheme.wello.helperhelp.light,
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: muiTheme.wello.helperhelp.light,
                                  },
                                  {
                                    title: 'Habitat inclusif',
                                    subtitle:
                                      'Vivre ensemble dans un espace de vie partagé et inséré dans la vie locale',
                                    link: '/solution-numerique-habitat-inclusif',
                                    icon: (
                                      <ResidenceIcon
                                        style={{
                                          fill: muiTheme.wello.residence.light,
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: muiTheme.wello.residence.light,
                                  },
                                  {
                                    title: 'EHPAD de demain',
                                    subtitle:
                                      'Faire le lien entre la vie à domicile et les services de l’Ehpad',
                                    link: '/solution-numerique-ehpad-de-demain',
                                    icon: (
                                      <RetirementIcon
                                        style={{
                                          fill: muiTheme.wello.retirement.light,
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: muiTheme.wello.retirement.light,
                                  },
                                ],
                              },
                              {
                                title: 'PARTENAIRES',
                                subtitle: 'Partenaires, Mutuelles, Fédération, Département…',
                                offers: [
                                  {
                                    title: 'Plateforme de services',
                                    subtitle:
                                      'Aide aux aidants, prévention, mobilité, services à domicile, relayage, empowercare…',
                                    link: '/solution-numerique-partenaires',
                                    icon: (
                                      <EstablishmentIcon
                                        style={{
                                          fill: '#5F1195',
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: '#5F1195',
                                  },
                                  {
                                    title: 'Plateforme de care management',
                                    subtitle:
                                      'Accompagner les aidants dans l’organisation du maintien à domicile de leurs proches',
                                    link: '/solution-numerique-care-manager',
                                    icon: (
                                      <CareIcon
                                        style={{
                                          fill: '#361492',
                                          marginRight: 12,
                                        }}
                                      />
                                    ),
                                    color: '#361492',
                                  },
                                ],
                              },
                            ],
                          },
                          { title: 'Tarifs', link: '/tarifs' },
                          { title: 'Partenaires', link: '/partenaires' },
                          { title: 'Blog', href: 'https://blog.wello.fr/' },
                          {
                            title: 'En savoir plus',
                            href: 'https://info.wello.fr/',
                            targetBlank: true,
                          },
                        ]}
                      />
                    </Switch>
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.una}>
                    <NavBar
                      logo={Images.una.home}
                      rightTree={[{ title: 'UNA.PRO.ROOM', link: '/pro' }]}
                    />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.lascalaa}>
                    <NavBar
                      logo={Images.scalaa.home}
                      rightTree={[{ title: 'SCALAA.PRO.ROOM', link: '/pro' }]}
                    />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.sla}>
                    <NavBar
                      logo={Images.sla.home}
                      cta={
                        <ButtonCallMeBackInModal
                          withContainedButton
                          // organisation={organisationSlaCTA}
                          withPostCode
                          withEmail
                          withPhone
                          buttonLabel={t('CONTACT_US')}
                          secondLabel={t('FREE_SERVICE')}
                          descriptionPlaceHolder={t(
                            'APP.SLA.ASK_CONTACT.TEXT_FIELD.LABEL.OTHER.PLACEHOLDER',
                          )}
                          isNavBar
                          gtmOrigin="home_card"
                        />
                      }
                    />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.mormal}>
                    <NavBar logo={Images.mormal.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.ccmormal}>
                    <NavBar logo={Images.ccmormal.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.diapason}>
                    <NavBar logo={Images.diapason.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.reprise}>
                    <NavBar
                      logo={Images.reprise.home}
                      rightTree={[
                        { title: 'REPRISE.METHODE.ROOM', link: '/methode-reprise' },
                        { title: 'REPRISE.PRO.ROOM', link: '/pro' },
                      ]}
                      // cta={
                      //   <ButtonCallMeBackInModal
                      //     withContainedButton
                      //     withPostCode
                      //     withEmail
                      //     withPhone
                      //     buttonLabel={t('CONTACT_US')}
                      //     descriptionPlaceHolder={t(
                      //       'APP.REPRISE.ASK_CONTACT.TEXT_FIELD.PLACEHOLDER',
                      //     )}
                      //     gtmOrigin="home_card"
                      //   />
                      // }
                    />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.cd85}>
                    <NavBar logo={getCdnUrl(`https://www.wello.fr/${Images.cd85.home}`, 200)} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.hapicoop}>
                    <Helmet>
                      <meta
                        name="google-site-verification"
                        content="IBfxRsd5FCac4IhdNjT18DAXpSNkiG284VkbFuO7af4"
                      />
                    </Helmet>
                    <NavBar
                      logo={Images.hapicoop.home}
                      logoLink="https://www.hapicoop.fr/nos-logements-1"
                    />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.chb}>
                    <NavBar logo={Images.chb.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.global}>
                    <NavBar logo={Images.global.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.ne}>
                    <NavBar logo={Images.ne.home} />
                  </HostConstraint>
                  <HostConstraint hosts={HOSTS_NAMES.soss}>
                    <NavBar logo={Images.soss.home} />
                  </HostConstraint>
                  <Switch>
                    {welloHost && <Route path="/" component={WelloHome} exact />}
                    {welloHost && <Route path="/pro" component={WelloHome} exact />}
                    {welloHost && <Route path="/tarifs" component={Pricing} exact />}
                    {welloHost && <Route path="/partenaires" component={Partner} exact />}
                    {welloHost && (
                      <Route path="/solution-numerique-aidants" component={OfferHelpers} exact />
                    )}
                    {welloHost && (
                      <Route
                        path="/solution-numerique-medico-social"
                        component={OfferHomeCare}
                        exact
                      />
                    )}
                    {welloHost && (
                      <Route path="/solution-numerique-care-manager" component={OfferCare} exact />
                    )}
                    {welloHost && (
                      <Route
                        path="/solution-numerique-partenaires"
                        component={OfferPartner}
                        exact
                      />
                    )}
                    {welloHost && (
                      <Route
                        path="/solution-numerique-ehpad-de-demain"
                        component={OfferRetirement}
                        exact
                      />
                    )}
                    {welloHost && (
                      <Route
                        path="/solution-numerique-habitat-inclusif"
                        component={OfferResidence}
                        exact
                      />
                    )}
                    {welloHost && (
                      <Route
                        path="/solution-numerique-aide-aux-aidants"
                        component={OfferHelperHelp}
                        exact
                      />
                    )}
                    {welloHost && (
                      <Redirect
                        from="/offre-aide-aux-aidants"
                        to="/solution-numerique-aide-aux-aidants"
                      />
                    )}
                    {welloHost && (
                      <Redirect
                        from="/offre-habitat-inclusif"
                        to="/solution-numerique-habitat-inclusif"
                      />
                    )}
                    {welloHost && (
                      <Redirect
                        from="/offre-etablissements"
                        to="/solution-numerique-ehpad-de-demain"
                      />
                    )}
                    {welloHost && (
                      <Redirect from="/offre-partenaires" to="/solution-numerique-partenaires" />
                    )}
                    {welloHost && (
                      <Redirect from="/offre-care-manager" to="/solution-numerique-care-manager" />
                    )}
                    {welloHost && (
                      <Redirect
                        from="/offre-medico-social"
                        to="/solution-numerique-medico-social"
                      />
                    )}
                    {welloHost && (
                      <Redirect from="/offre-aidants" to="/solution-numerique-aidants" />
                    )}

                    {welloHost && (
                      <Route path="/structures" component={WelloSearchResultsMain} exact />
                    )}
                    {unaHost && <Route path="/" component={UnaHome} exact />}
                    {unaHost && (
                      <Route
                        path="/leagues/:league_id/cities/:city_slug"
                        component={UnaSearchResults}
                        exact
                      />
                    )}
                    {unaHost && (
                      <Route
                        path="/leagues/:league_id/cities/:city_slug/services/:service_slug"
                        component={UnaSearchResults}
                        exact
                      />
                    )}
                    {unaHost && (
                      <Route path="/cities/:city_slug" component={UnaSearchResults} exact />
                    )}
                    {unaHost && (
                      <Route
                        path="/cities/:city_slug/services/:service_slug"
                        component={UnaSearchResults}
                        exact
                      />
                    )}
                    {unaHost && <Route path="/pro" component={UnaPro} exact />}
                    {unaHost && <Route path="/structures" component={UnaSearchResults} exact />}

                    {lascalaaHost && <Route path="/" component={ScalaaHome} exact />}
                    {lascalaaHost && <Route path="/pro" component={ScalaaPro} exact />}
                    {mormalHost && <Route path="/" component={MormalHome} exact />}
                    {mormalHost && (
                      <Redirect
                        from="/leagues/:league_id/cities/:city_slug/transport"
                        to="/recherche/transport/:city_slug"
                      />
                    )}
                    {mormalHost && (
                      <Route
                        path="/recherche/transport/:city_slug"
                        component={MormalSearchResults}
                        exact
                      />
                    )}
                    {mormalHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(MormalSearchResultsAlgolia)}
                        exact
                      />
                    )}
                    {slaHost && <Route path="/" component={SLAHome} exact />}
                    {slaHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(SLASearchResults)}
                        exact
                      />
                    )}
                    {slaHost && <Route path="/video" component={SLAVideo} exact />}
                    {ccmormalHost && <Route path="/" component={CCMormalHome} exact />}
                    {ccmormalHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(CCMormalSearchResults)}
                        exact
                      />
                    )}
                    {diapasonHost && <Route path="/" component={DiapasonHome} exact />}
                    {repriseHost && <Route path="/" component={RepriseHome} exact />}
                    {repriseHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(RepriseSearchResults)}
                        exact
                      />
                    )}
                    {repriseHost && <Route path="/pro" component={ReprisePro} exact />}
                    {repriseHost && (
                      <Route path="/methode-reprise" component={RepriseMethode} exact />
                    )}
                    {cd85Host && <Route path="/" component={CD85Home} exact />}
                    {cd85Host && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(CD85SearchResults)}
                        exact
                      />
                    )}
                    {hapicoopHost && <Route path="/" component={HapicoopHome} exact />}
                    {chbHost && (
                      <Route
                        path="/"
                        // component={CHBHome}
                        exact
                      >
                        <Redirect to="/aidants/centre-helene-borel/" />
                      </Route>
                    )}
                    {chbHost && (
                      <Route path="/aide-au-financement" component={CHBHelpedTool} exact />
                    )}
                    {chbHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(CHBSearchResults)}
                        exact
                      />
                    )}
                    {globalHost && <Route path="/" component={GlobalHome} exact />}
                    {globalHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(GlobalSearchResults)}
                        exact
                      />
                    )}
                    {neHost && <Route path="/" component={NEHome} exact />}
                    {neHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(NESearchResults)}
                        exact
                      />
                    )}
                    {neHost && <Route path="/" component={NEHome} exact />}
                    {neHost && <Route path="/type/:type_slug?" component={NESearchResults} exact />}
                    {sossHost && <Route path="/" component={SOSSHome} exact />}
                    {sossHost && (
                      <Route
                        path="/recherche/:type_slug"
                        component={CheckTabValue(SOSSSearchResults)}
                        exact
                      />
                    )}
                    {/* // To be deprecated */}
                    <Route
                      path={[
                        '/type/organisations/services/:service_slug?/cities/:city_slug?',
                        '/type/organisations/services/:service_slug?',
                        '/type/organisations/cities/:city_slug?',
                      ]}
                      exact
                    >
                      <Redirect to="/recherche/structures" />
                    </Route>
                    <Route
                      path={[
                        '/type/tasks/services/:service_slug?/cities/:city_slug?',
                        '/type/tasks/services/:service_slug?',
                        '/type/tasks/cities/:city_slug?',
                      ]}
                      exact
                    >
                      <Redirect to="/recherche/activites" />
                    </Route>
                    <Route
                      path={[
                        '/type/articles/services/:service_slug?/cities/:city_slug?',
                        '/type/articles/services/:service_slug?',
                        '/type/articles/cities/:city_slug?',
                      ]}
                      exact
                    >
                      <Redirect to="/recherche/articles" />
                    </Route>
                    <Route
                      path={[
                        '/type/courses/services/:service_slug?/cities/:city_slug?',
                        '/type/courses/services/:service_slug?',
                        '/type/courses/cities/:city_slug?',
                      ]}
                      exact
                    >
                      <Redirect to="/recherche/formations" />
                    </Route>
                    <Route
                      path={[
                        '/type/clubs/services/:service_slug?/cities/:city_slug?',
                        '/type/clubs/services/:service_slug?',
                        '/type/clubs/cities/:city_slug?',
                      ]}
                      exact
                    >
                      <Redirect to="/recherche/communautes" />
                    </Route>
                    <Route path="/nos-offres/solutions-repit" exact>
                      <Redirect to="/articles/les-solutions-de-repit" />
                    </Route>
                    <Route path="/nos-offres/bulle-air" exact>
                      <Redirect to="/articles/le-service-repit-bulle-d-air" />
                    </Route>
                    <Route path="/nos-offres/soutien-aidants" exact>
                      <Redirect to="/articles/le-soutien-aux-aidants" />
                    </Route>
                    <Route path="/nos-offres/informations" exact>
                      <Redirect to="/articles/informations-et-formations" />
                    </Route>
                    <Route path="/nos-offres/activites" exact>
                      <Redirect to="/articles/des-activites-et-ateliers-pour-les-aidants-et-les-aides" />
                    </Route>
                    <Route path="/nos-offres/conge-proche-aidant" exact>
                      <Redirect to="/articles/le-conge-de-proche-aidant" />
                    </Route>
                    <Route path="/nos-offres/services-plateformes-repit" exact>
                      <Redirect to="/articles/les-services-des-plateformes-de-repit" />
                    </Route>
                    <Route path="/nos-offres/aides-financieres" exact>
                      <Redirect to="/articles/quelles-aides-financieres-pour-mon-proche" />
                    </Route>
                    <Route
                      path="/conditions-generales-d-utilisation"
                      component={chbHost ? CHBCgu : Cgu}
                      exact
                    />
                    <Route
                      path="/politique-de-confidentialite"
                      component={chbHost ? CHBPrivacy : Privacy}
                      exact
                    />
                    <Route path="/mentions-legales" component={Mention} exact />
                    <Route path="/nos-offres/:offer" component={Page} />
                    <Route path="/nos-conseils/:advice" component={Page} />
                    <Route path="/quick/action/leads/:id/:action" component={LeadQuickActions} />
                    <Route path="/auth/email/:token" component={EmailToken} exact />
                    <Route path="/signin" component={SignIn} exact />
                    <Route path="/passwords/reset" component={ResetPassword} exact />
                    <Route path="/passwords/set" component={ResetPassword} exact />
                    <Route path="/passwords/new/:token" component={NewPassword} exact />
                    <Route path="/statistics" exact component={Authentication(Statistics)} />
                    <Route path="/notifications" component={Notifications} exact />
                    <Route path="/la-rhonelle" exact>
                      <Redirect to="/la-rhonelle/la-rhonelle-villereau" />
                    </Route>
                    <Route path="/organisations/mda-nantes" exact>
                      <Redirect to="/maison-des-aidants/mda-nantes" />
                    </Route>
                    <Route path="/organisations/scalaa" exact>
                      <Redirect to="/la-scalaa/scalaa" />
                    </Route>
                    <Route path="/organisations/la-scalaa-lens" exact>
                      <Redirect to="/pfr-le-temps-d-une-pause/la-scalaa-lens" />
                    </Route>
                    <Route path="/organisations/familles-rurales-avesnois-mormal" exact>
                      <Redirect to="/familles-rurales/familles-rurales-avesnois-mormal" />
                    </Route>
                    <Route path="/organisations/vital-services-mobilite" exact>
                      <Redirect to="/vital-services-mobilite/vital-services-mobilite" />
                    </Route>
                    <Route path="/organisations/amf-ad" exact>
                      <Redirect to="/amf-ad/amf-ad" />
                    </Route>
                    <Route path="/organisations/seniors-et-alors-nantes" exact>
                      <Redirect to="/seniors-et-alors/seniors-et-alors-nantes" />
                    </Route>
                    <Route
                      path="/settings/security"
                      exact
                      component={Authentication(UserSecurity)}
                    />
                    <Route path="/articles/:article_slug" component={ShowArticle} exact />
                    <Route path="/formations/:course_slug" component={ShowCourse} exact />
                    <Route
                      path="/formations/:course_slug/chapitres/:chapter_id/success"
                      component={CourseUnitSuccess}
                      exact
                    />
                    <Route
                      path="/formations/:course_slug/chapitres/:chapter_id"
                      component={ShowChapter}
                      exact
                    />
                    <Route
                      path="/signin/:holding_slug/:organisation_slug/required"
                      component={withOrganisation(OrganisationSignInRequired)}
                      exact
                    />
                    <Route
                      exact
                      path="/signup/on/:holding_slug/:organisation_slug/:role(volunteer|primary|nurse|pro|neighbor|family|main_helper|friend|ergo|doctor|kine|psychologist|coordinator|caregiver|personal_carer|exterior)"
                      component={withOrganisation(OrganisationSignUp)}
                    />
                    <Route
                      path="/confirmations/on/:holding_slug/:organisation_slug/new"
                      component={withOrganisation(OrganisationResendConfirmationEmail)}
                      exact
                    />
                    <Route
                      path="/signup/pending/:holding_slug/:organisation_slug"
                      component={withOrganisation(OrganisationSignUpPending)}
                      exact
                    />
                    <Route
                      path="/signup/confirmation/:holding_slug/:organisation_slug/:token/:email"
                      component={withOrganisation(SignUpFinalization)}
                      exact
                    />

                    <Route
                      path="/signup/invitation/family/:family_slug/:token/:email"
                      component={SignUpFinalization}
                      exact
                    />
                    <Route
                      path="/signup/invitation/:holding_slug/:organisation_slug/:token/:email"
                      component={withOrganisation(SignUpFinalization)}
                      exact
                    />
                    <Route
                      path="/signup/invitation/:holding_slug/:token/:email"
                      component={SignUpFinalization}
                      exact
                    />
                    <Route
                      path="/signup/verification/:token/:email"
                      component={SignUpFinalization}
                      exact
                    />
                    <Route
                      path="/signup/confirmation/:token/:email"
                      component={SignUpConfirmation}
                      exact
                    />
                    <Route
                      path="/users/statistics/:holding_slug/:organisation_slug"
                      exact
                      component={withOrganisation(Authentication(OrganisationStatistics))}
                    />
                    <Route
                      path="/users/statistics/:holding_slug"
                      exact
                      component={withHolding(Authentication(HoldingStatistics))}
                    />
                    <Route
                      path="/leagues/:league_id/leads/details/:lead_id"
                      component={withLeague(Authentication(LeagueShowLead))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/settings"
                      component={withLeague(Authentication(LeagueSettingsIndex))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/analytics"
                      component={withLeague(Authentication(LeagueAnalyticsIndex))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/holdings"
                      component={withLeague(Authentication(LeagueHoldingsIndex))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/helpers/:helper_id"
                      component={withLeague(Authentication(LeagueShowHelper))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/helpers"
                      component={withLeague(Authentication(LeagueHelpersIndex))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/articles"
                      component={withLeague(
                        Authentication(AclCurrentUserBelongsToLeague('')(LeagueArticlesIndex)),
                      )}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/articles/new"
                      exact
                      component={withLeague(AclCurrentUserBelongsToLeague('')(LeagueArticleForm))}
                    />
                    <Route
                      path="/leagues/:league_id/articles/:article_slug/edit"
                      component={withArticleAndLeague(
                        AclCurrentUserBelongsToLeague('')(LeagueArticleForm),
                      )}
                      exact
                    />
                    {/* // To be deprecated */}
                    <Redirect
                      from="/leagues/:league_id/articles/:article_slug"
                      to="/articles/:article_slug"
                    />
                    {/* // To be deprecated */}
                    <Redirect
                      from="/leagues/:league_id/courses/:course_slug"
                      to="/formations/:course_slug"
                    />
                    <Redirect
                      from="/leagues/:league_id/courses/:course_slug/chapters/:chapter_id/success"
                      to="/formations/:course_slug/chapitres/:chapter_id/success"
                    />
                    <Redirect
                      from="/leagues/:league_id/courses/:course_slug/chapters/:chapter_id"
                      to="/formations/:course_slug/chapitres/:chapter_id"
                    />
                    <Redirect
                      from="/leagues/:league_id/courses"
                      to="/leagues/:league_id/formations"
                    />
                    <Redirect
                      from="/leagues/:league_id/courses/:course_slug/edit"
                      to="/leagues/:league_id/formations/:course_slug/edit"
                    />
                    <Redirect
                      from="/leagues/:league_id/courses/:course_slug/chapters/:chapter_id/edit"
                      to="/leagues/:league_id/formations/:course_slug/chapitres/:chapter_id/edit"
                    />
                    <Route
                      path="/leagues/:league_id/formations"
                      component={withLeague(
                        Authentication(AclCurrentUserBelongsToLeague('')(LeagueCoursesIndex)),
                      )}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/formations/:course_slug/edit"
                      exact
                      component={withLeague(AclCurrentUserBelongsToLeague('')(LeagueCourseManage))}
                    />
                    <Route
                      path="/leagues/:league_id/formations/:course_slug/chapitres/:chapter_id/edit"
                      exact
                      component={withLeague(AclCurrentUserBelongsToLeague('')(LeagueChapterManage))}
                    />
                    <Route
                      path="/leagues/:league_id/communities"
                      component={withLeague(Authentication(LeagueCommunitiesIndex))}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/communities/new"
                      component={Authentication(
                        AclCurrentUserBelongsToLeague('')(LeagueCommunityForm),
                      )}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/communities/:holding_slug/edit"
                      component={withHolding(
                        AclCurrentUserBelongsToLeague('')(LeagueCommunityForm),
                      )}
                      exact
                    />
                    <Route
                      path="/leagues/:league_id/holdings/new"
                      exact
                      component={Authentication(HoldingNew)}
                    />
                    <Redirect from="/leagues/:league_id/" to="/leagues/:league_id/helpers" />
                    <Route
                      path="/quick-access/organisations/:identifier"
                      component={withStructure(QuickAccessForm, 'quick_access')}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/"
                      component={withFamily(AclCurrentUserBelongsToFamily('')(FamilyHome), 'full')}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/leads"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('leads:read')(FamilyLeads),
                        'full',
                      )}
                      exact
                    />
                    <Redirect
                      from="/families/:family_slug/courses"
                      to="/families/:family_slug/formations"
                    />
                    <Route
                      path="/families/:family_slug/formations"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('courses:read')(FamilyCourses),
                        'full',
                      )}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/schedule"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('')(FamilySchedule),
                        'full',
                      )}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/transport"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('')(FamilyTransport),
                        'full',
                      )}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/schedule/new"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('tasks:create')(OrganisationTaskStepForm),
                      )}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/schedule/:task_slug/edit"
                      component={enhancedEditFamilyTaskForm}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/schedule/:task_slug/copy"
                      component={enhancedCopyFamilyTaskForm}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/schedule/:task_slug/date/:event_date"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('')(OrganisationShowTwig),
                        'full',
                      )}
                      exact
                    />
                    <Route
                      path="/families/:family_slug/care-notebook"
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('')(CareNotebookIndex),
                        'full',
                      )}
                      exact
                    />
                    {/* // To be deprecated */}
                    <Redirect
                      from="/families/:family_slug/care-notebooks/:carenotebook_slug"
                      to="/families/:family_slug/care-notebook"
                    />
                    <Route
                      path="/families/:family_slug/leads/details/:lead_id"
                      exact
                      component={withFamily(
                        AclCurrentUserBelongsToFamily('leads:read')(FamilyShowLead),
                      )}
                    />
                    <Route
                      path="/settings/:holding_slug/organisations/new"
                      exact
                      component={withHolding(Authentication(OrganisationNew))}
                    />
                    <Route
                      path="/leagues/:league_id/candidate"
                      component={withLeague(LeaguesCandidate)}
                      exact
                    />
                    <Route
                      path="/communities/:holding_slug/clubs/:club_id/members"
                      component={enhancedClubMembers}
                      exact
                    />
                    <Route
                      path="/communities/:holding_slug/:tab_value/:item_id"
                      component={HomeCommunity}
                    />
                    <Route path="/communities/:holding_slug/" component={HomeCommunity} exact />
                    <Route path="/communities/:holding_slug/:tab_value" component={HomeCommunity} />
                    <Route
                      path="/:holding_slug/:organisation_slug/users/setup/success"
                      exact
                      component={withOrganisation(Authentication(SetupSuccess))}
                    />
                    <Route path="/rails" />
                    <Route
                      path="/:holding_slug([A-Za-z0-9\-]+)"
                      component={withHolding(HoldingHome)}
                      exact
                    />
                    <Route
                      path="/:holding_slug/leads"
                      component={Authentication(withHolding(HoldingLeadsIndex))}
                      exact
                    />
                    <Route
                      path="/:holding_slug/articles"
                      component={withHolding(HoldingHome, 'articles')}
                      exact
                    />
                    <Route
                      path="/:holding_slug/articles/new"
                      exact
                      component={Authentication(
                        withHolding(AclCurrentUserBelongsToHolding('')(HoldingArticleForm)),
                      )}
                    />
                    <Route
                      path="/:holding_slug/articles/:article_slug/edit"
                      component={Authentication(
                        withArticleAndHolding(
                          AclCurrentUserBelongsToHolding('')(HoldingArticleForm),
                        ),
                      )}
                      exact
                    />
                    <Redirect
                      from="/:holding_slug/articles/:article_slug"
                      to="/articles/:article_slug"
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/services/:service_slug"
                      component={OrganisationShowService}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/quotation/success"
                      component={withOrganisation(QuotationSuccess)}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/quotation/:service_id?"
                      component={withOrganisation(Quotation, 'services')}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/quotation/cities/:city?"
                      component={withOrganisation(Quotation, 'services')}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/leads/details/:lead_id"
                      exact
                      component={withOrganisation(
                        AclCurrentUserBelongsToOrganisation('leads:read')(OrganisationShowLead),
                        'holding',
                      )}
                    />
                    {/* to be deprecated */}
                    <Redirect
                      from="/:holding_slug/courses/:course_slug"
                      to="/formations/:course_slug"
                    />
                    <Redirect
                      from="/:holding_slug/courses/:course_slug/chapters/:chapter_id"
                      to="/formations/:course_slug/chapitres/:chapter_id"
                    />
                    <Redirect
                      from="/:holding_slug/courses/:course_slug/chapters/:chapter_id/success"
                      to="/formations/:course_slug/chapitres/:chapter_id/success"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/courses/:course_slug/edit"
                      to="/:holding_slug/:organisation_slug/formations/:course_slug/edit"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/courses/:course_slug/chapitres/:chapter_id/edit"
                      to="/:holding_slug/:organisation_slug/formations/:course_slug/chapitres/:chapter_id/edit"
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/formations/:course_slug/edit"
                      component={Authentication(
                        withHolding(
                          AclCurrentUserBelongsToOrganisation('courses:create')(
                            HoldingCourseManage,
                          ),
                        ),
                      )}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/formations/:course_slug/chapitres/:chapter_id/edit"
                      exact
                      component={Authentication(
                        withHolding(
                          AclCurrentUserBelongsToOrganisation('courses:create')(
                            HoldingChapterManage,
                          ),
                        ),
                      )}
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/clubs/:club_id/members"
                      component={enhancedClubMembers}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/articles/new"
                      exact
                      component={Authentication(
                        withOrganisation(
                          AclCurrentUserBelongsToOrganisation('articles:create')(
                            HoldingArticleForm,
                          ),
                        ),
                      )}
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/articles/:article_slug/edit"
                      component={Authentication(
                        withOrganisation(
                          AclCurrentUserBelongsToOrganisation('articles:create')(
                            withHoldingArticle(HoldingArticleForm),
                          ),
                        ),
                      )}
                      exact
                    />
                    {/* // To be deprecated */}
                    <Redirect
                      from="/:holding_slug/:organisation_slug/tasks/new"
                      to="/:holding_slug/:organisation_slug/activites/new"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/tasks/:task_slug/edit"
                      to="/:holding_slug/:organisation_slug/activites/:task_slug/copy"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/tasks/:task_slug/copy"
                      to="/:holding_slug/:organisation_slug/activites/:task_slug/copy"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/tasks/:task_slug/date/:event_date"
                      to="/:holding_slug/:organisation_slug/activites/:task_slug/date/:event_date"
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/activites/new"
                      component={withOrganisation(
                        AclCurrentUserBelongsToOrganisation('tasks:create')(
                          OrganisationTaskStepForm,
                        ),
                      )}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/activites/:task_slug/edit"
                      component={enhancedEditTaskForm}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/activites/:task_slug/copy"
                      component={enhancedCopyTaskForm}
                      exact
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/activites/:task_slug/date/:event_date"
                      component={OrganisationShowTwig}
                      exact
                    />
                    {/* // To be deprecated */}
                    <Redirect
                      from="/:holding_slug/:organisation_slug/events"
                      to="/:holding_slug/:organisation_slug/activites"
                    />
                    <Redirect
                      from="/:holding_slug/:organisation_slug/courses"
                      to="/:holding_slug/:organisation_slug/formations"
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/:tab_value/:item_id"
                      component={OrganisationHome}
                    />
                    <Route
                      path="/:holding_slug/:organisation_slug/:tab_value"
                      component={OrganisationHome}
                    />
                    <Route
                      path="/:holding_slug([A-Za-z0-9\-]+)/:organisation_slug([A-Za-z0-9\-]+)"
                      component={OrganisationHome}
                      exact
                    />
                    <Route component={NoMatch404} />
                    <Route component={NoMatch401} />
                  </Switch>
                </div>
              </Switch>
            </main>
          </ScrollManager>
          <Toaster />
        </DynamicRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
}

export default withStyles(styles)(withRoot(App));
